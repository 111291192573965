<template>
  <div class="single-sorting-container">

    <div class="header">
      <div style="width: 205px">
        <logotop></logotop>
      </div>
      <div class="sortingformbox">
        <div class="box-left">
          <a-button class="back-btn bgBlue"  type="primary" style="padding:0px 28px;" @click="go_back">
            <a-icon type="arrow-left"/>
            返回
          </a-button>
          <a-button class="back-btn bgBlue"  type="primary" style="padding:0px 28px;" @click="reloadAction">
            <a-icon type="reload"/>
            刷新
          </a-button>
        </div>
        <div class="btn-form-line"></div>
        <div class="form-container">
          <a-config-provider :locale="locale">
            <a-form-model layout="inline" class="sortingForm" style="width:100%" >
              <a-form-model-item class="itemFormItem" style="flex: 1">
                <a-input v-model="dataObj.product_name" placeholder="商品名称" style="text-align: center"  @change="searchChange">
                  <a-icon slot="suffix" type="search" :style="{fontSize: '30px', color: '#d9d9d9'}" />
                </a-input>
              </a-form-model-item>

            </a-form-model>
          </a-config-provider>

        </div>
      </div>
    </div>


    <a-spin :spinning="loading">

      <div class="content-view">
      <div class="soring-info-view" v-if="otherInfo && otherInfo">
        <div class="info-item">
          <span class="info-item-title">下单数量：</span>
          <span class="info-item-value">{{otherInfo.total_order_sum}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">已分拣数量：</span>
          <span class="info-item-value">{{otherInfo.total_sorting_sum}}</span>
          <span class="info-item-value-unit">{{otherInfo.unit}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">未分拣：</span>
          <span class="info-item-value">{{otherInfo.total_residue_sum}}</span>
          <span class="info-item-value-unit">{{otherInfo.unit}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">分拣差异数量：</span>
          <span class="info-item-value">{{otherInfo.total_sorting_diff_sum}}</span>
          <span class="info-item-value-unit" style="color: #BC1D00">{{otherInfo.unit}}</span>
        </div>
        <!-- <div class="info-item">
            <span class="info-item-title">分拣差异数量：</span>
            <span class="info-item-value">{{otherData.total_sorting_diff_sum}}</span>
            <span class="info-item-value-unit" style="color: #BC1D00">斤</span>
        </div> -->
      </div>

      <a-empty class="nodataempty" v-if="datalist.length==0">
        <span slot="description">暂无数据</span>
      </a-empty>
      <div class="sorting-detail-view" :class="{'sorting-detail-view-height': !!otherInfo}" @scroll="listenScroll" v-if="datalist.length!==0">
        <a-row :gutter="10">
          <a-col class="gutter-row" :span="6" v-for="(item,index) in datalist" :key="index"
                 @click="sortingAction(item)">
            <div class="sorting-detail-item" :class="[item.note ? 'item-border' : '', 'border-' + item.sorting_status]">
              <div class="detail-item-header">
                <div class="prod-name">{{item.product_name}}</div>
                <div class="header-right">
                  <div class="item-status">
                    <div :class="'status-' + item.sorting_status"/>
                    {{item.sorting_status == '0' ? '待分拣' : '已分拣'}}
                  </div>
                  <div class="out-sign" v-if="item.stock_status==1 && index === 2">缺</div>
                </div>
              </div>
              <div style="flex: 1"></div>
<!--              <div class="entity-name-container" :ref="'entity-name-container' + index">-->
<!--                <div class="entity-name" :ref="'entity-name' + index" :style="{'transform': item.client.nameScale}">-->
<!--                  {{item.client.name}}-->
<!--                </div>-->
<!--              </div>-->

              <div class="mark-view">
                <div class="mark-text">{{item.note}}</div>
              </div>
              <div class="num-info">
                <div>
                  <span>订购：</span>
                  <span>{{item.order_sum}}{{item.base_unit}}</span>
                </div>
                <div>
                  <span>称重：</span>
                  <span>{{item.sorting_sum || '0'}}{{item.base_unit}}</span>
                </div>
              </div>
              <div class="border-line" :class="'statusBg-' + item.sorting_status"/>
            </div>
          </a-col>

        </a-row>
        <load-more :status="loadingType" style="margin-top:10px"></load-more>
      </div>
      </div>
    </a-spin>

    <div class="footer-container">


      <div class="sortPrint" @click="hasCheckPrintChange">
           <span class="radioStyle" :class="has_check_print?'nochoseClick':'choseClick'">
               <a-icon type="check"/>
           </span>
        <span>分拣时不打印标签 <a-tooltip placement="left">
		                    <template slot="title">
		                        <span>开启后，分拣时不打印分拣小票</span>
		                    </template>
		                    <a-icon type="exclamation-circle"/>
		                </a-tooltip></span>
      </div>


      <div class="footer-btns">
        <a-button class="form-btn reset-btn" @click="printAll">一键打印</a-button>
        <div class="bgBlue" style="display: flex; align-items: center">
          <a-button class="sorting-btn" style="width: 119px" @click="showBatchSortConfirm">一键分拣</a-button>
          <div class="line"/>
          <a-popover placement="top" v-model="visible" trigger="click">
            <a-button
                    style="
                                font-size: 20px;
                                font-weight: 600;
                                color: #1E3B98;
                                border: none;
                                width: 160px;
                                height: 40px;" slot="content" class="popover-btn" @click="coverAllsort">
              一键恢复
            </a-button>
            <a-button class="sorting-sub-btn" style="width: 56px" icon="down"></a-button>
          </a-popover>
        </div>
      </div>
    </div>
    <a-drawer
            :width="640"
            placement="right"
            :closable="false"
            :visible="coversortShow"
            mask
            @close="coversortShow=false"
            class="drawBoxcommon">
      <div>
        <div class="title">
          一键恢复
          <a-icon type="close-circle" class="close" @click="coversortShow=false"/>
        </div>
        <a-table :data-source="Datarecovery" :columns="tabrecovery" :pagination="paginationvery"
                 :loading="tableLoadingone">
          <template slot="created_at" slot-scope="text, record">
            <span>{{timeConvertData(record.created_at)}}</span>
          </template>
          <template slot="active" slot-scope="text, record">
            <a-popconfirm title="确定恢复成待分拣?" cancel-text="取消" ok-text="确定" @confirm="() => upDatavery(record.id)">
              <a-button type="primary" size="small" style="background:#203EA0;border-color:#203EA0">
                一键恢复
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </a-drawer>

  </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo
  import {PrintAccount, CheckIsP} from '@/assets/js/doPrint'
  import loadMore from "@/components/loadMore"; //顶部查询
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import {timeConvert} from '@/assets/js/common'
  import moment from 'moment';
  import {mapMutations} from "vuex";

  export default {
    name: "single_sorting",
    components: {
      logotop,
      loadMore
    },
    data() {
      return {
        cateList: [],
        cateData: [],
        sortStatusList: [],
        printUrl: '',
        oldUrl: 'null',
        fieldNames: {
          label: 'label',
          value: 'value',
          children: 'children'
        },
        visible: false,
        coversortShow: false,
        Datarecovery: [],
        clientName:  null,
        tabrecovery: [
          {title: 'ID', dataIndex: 'id', key: 'id'},
          {title: '商品名称', dataIndex: 'product_name', key: 'product_name', width: 130, ellipsis: true},
          {title: '分拣员', dataIndex: 'admin.name', key: 'admin.name', width: 80, ellipsis: true},
          {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 160,
            ellipsis: true,
            scopedSlots: {customRender: 'created_at'}
          },
          {title: '操作', dataIndex: 'active', key: 'active', scopedSlots: {customRender: 'active'}},
        ],
        paginationvery: {
          total: 0,
          pageSize: 10,
          showTotal: total => `共 ${total} 条`
        },
        tableLoadingone: false,
        batch: { // 批量操作
          print: [], // 批量打印id
          sort: [], // 批量分拣id
        },
        locale: zhCN,
        LOCK_STATUS: false,
        loading: false,
        otherInfo: null,
        loadingType: 'loading',
        datalist: [],
        has_check_print: true,
        moment,
        lines: [],
        dataObj: {
          send_time: null,
          product_name: '',
          sorting_client_id: null
        },
        page: 1,
        productList: [],
        productLoading: false,

        userList: [],
        userLoading: false,
        inputWidth: '170px',
        searchTimeout: null
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.oldUrl = from.name
      })
    },
    activated() {
      this.$nextTick(() => {
        if (this.oldUrl !== 'SortOperate') {
          this.datalist = [];
          this.dataObj = {
            send_time: null,
            product_name: '',
            sorting_client_id: null
          }
          if (this.$route.query.sorting_client_id) {
            this.dataObj.send_time  = this.$route.query.send_time;
            this.dataObj.sorting_client_id = this.$route.query.sorting_client_id
            if (!this.dataObj.send_time) {
              let newDate = new Date();
              this.dataObj.send_time = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
            }
            this.clientName = this.$route.query.client

            this.page = 1;
            this.loadData(this.page)
          }

        }
      })
    },
    created() {
      // let newDate = new Date();
      // this.dataObj.send_time = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
    },
    mounted() {

      this.getRrintUrl();
      this.getclassfiy();
      this.getPrintStatus();//获取是否打印标签

      this.dataObj.send_time  = this.$route.query.send_time;
      this.dataObj.sorting_client_id = this.$route.query.sorting_client_id
      if (!this.dataObj.send_time) {
        let newDate = new Date();
        this.dataObj.send_time = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
      }
      this.clientName = this.$route.query.client

      this.page = 1;
      this.loadData(this.page)


    },

    methods: {
      searchChange(e) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          this.beforeLoadData('r')
        }, 500)
      },
      reloadAction() {
        window.location.reload()
      },
      formChange() {
        console.log('change')
      },
      cateChange(e) {
        console.log('change', e)
        this.dataObj.cate = e[e.length - 1]
        this.beforeLoadData('r')
      },
      getPrintStatus() {
        this.rq.get('/merapi/sorting/v2/sorting/get-tag-print-status').then(res => {
          console.log(res.data)
          if (res.data.status === 0) {
            this.has_check_print = false;
          } else {
            this.has_check_print = true;
          }
        })
      },
      getRrintUrl(e) {
        this.loading = true;
        this.rq.get('/merapi/sorting/v2/print/get-print-url').then(res => {
          for (let item of res.data) {
            if (item.key == 'print_tag') {
              this.printUrl = item.host + item.data[0].url;
            }
          }
        }).catch(err => {
        })
      },
      getclassfiy() {
        this.rq.get('/merapi/sorting/v2/common/code-list').then(res => {

          this.lines = res.data.lineList
          this.cateList = res.data.cateList
          this.sortStatusList = res.data.sortStatusList
        })
      },

      upDatavery(id) {
        this.tableLoadingone = true;
        this.rq.get('/merapi/sorting/v2/sorting/restore?id=' + id).then(res => {
          this.getArecovery()
          this.$message.success('恢复成功。');
          this.page = 1;
          this.loadData(this.page)
        }).catch(err => {
          this.tableLoadingone = false;
        })
      },
      timeConvertData(e) {
        return timeConvert(e, 1)
      },
      coverAllsort() {
        this.coversortShow = true;
        this.getArecovery();

        this.visible = false
      },
      getArecovery() {
        this.tableLoadingone = true;

        this.rq.get('/merapi/sorting/v2/sorting/restore-list').then(res => {
          this.tableLoadingone = false;
          this.Datarecovery = res.data.data;
        }).catch(err => {
          this.tableLoadingone = false;
        })
      },
      // 这是一键分拣
      showBatchSortConfirm() {
        var that = this;
        var param = that.batch.sort;
        if (that.has_check_print) {
          if (!CheckIsP()) {
            return;
          }
        }
        if (param.length == 0) {
          that.$notification['error']({
            message: '温馨提示',
            description: '可用分拣选项为空~',
          });
          return;
        }
        that.$confirm({
          title: '确认',
          content: h => `将有${param.length}个标签被打印(注意：此功能会分拣并打印所有未分拣的商品)，确定全部打印？`,
          okText: '确定',
          okType: 'info',
          cancelText: '取消',
          onOk() {
            if (param.length > 0) {
              param = JSON.stringify(param);
              param = {item: param};
              that.loading = true
              that.rq.post('/merapi/sorting/v2/sorting/batch-sort', param).then(res => {
                var data = res.data;
                if (that.has_check_print) {
                  for (var i = 0; i < data.length; i++) {
                    var url = that.printUrl + '?' + data[i]['url'] + '&type=tag'
                    PrintAccount('批量打印', url, 'print')
                  }
                }
                that.page = 1;
                that.loadData(that.page)

              }).catch(e => {
                that.loading = false;
              })
            }
          },
          onCancel() {

          },
          class: 'test',
        });
      },
      printAll() {
        if (!CheckIsP()) {
          return;
        }
        var that = this;
        var param = that.batch.print;
        if (param.length == 0) {
          that.$notification['error']({
            message: '温馨提示',
            description: '可用打印选项为空~',
          });
          return;
        }
        that.$confirm({
          title: '确认',
          content: h => `将有${param.length}个标签被打印（注意：此功能会打印所有标签包括所有已分拣和未分拣的）确定打印？`,
          okText: '确定',
          okType: 'info',
          cancelText: '取消',
          onOk() {
            if (param.length > 0) {
              param = JSON.stringify(param);
              param = {item: param};
              that.loading = true;
              that.rq.post('/merapi/sorting/v2/sorting/batch-print', param).then(res => {
                that.loading = false;
                var data = res.data;
                for (var i = 0; i < data.length; i++) {
                  var url = that.printUrl + '?' + data[i]['url'] + '&type=tag'
                  console.log(url)
                  PrintAccount('批量打印', url, 'print')
                }
              }).catch(e => {
                that.loading = false;
              })
            }
          },
          onCancel() {
          },
          class: 'test',
        });
      },
      hasCheckPrintChange(e) { // 是否打印标签
        this.has_check_print = !this.has_check_print
        var status = this.has_check_print ? 1 : 0;
        var param = {
          status: status
        }
        this.rq.post('/merapi/sorting/v2/sorting/update-tag-print-status', param).then(res => {
          console.log('打印标识已传入后台')
        })
      },
      go_back() {
        this.$router.back()
      },
      onChange(e, datestring) {
        this.dataObj.send_time = datestring;

        this.beforeLoadData('r')
      },
      listenScroll(e) {
        if (this.LOCK_STATUS) return;
        if (e instanceof Event) {
          var el = e.target;
          var scHeight = el.scrollHeight, scTop = el.scrollTop, clHeight = el.clientHeight;
          //距离底部100px时，开始加载数据
          if (scHeight - scTop > clHeight + 20) return;
        }
        this.LOCK_STATUS = true;
        if (this.loadingType === 'nomore' || this.datalist.length === 0) return;
        this.page++;
        this.loadData(this.page);
      },

      search() {
        this.page = 1;
        this.loadData(this.page)
      },
      sortingAction(item) {
        var data = {
          client: this.clientName,
          id: item.id,
          base_unit: item.base_unit,
          sorting_status: item.sorting_status,
          sorting_status_name: item.sorting_status_name,
          order_sum: item.order_sum,
          sorting_sum: item.sorting_sum,
          stock_status: item.stock_status,
          commodity_name: item.commodity_name,
          order_id: item.order_id,
          sortingprint: this.has_check_print
        };
        localStorage.setItem('sortingdatac', JSON.stringify(data))
        this.$router.push({
          name: 'SortOperate',
          query: data
        })
      },
      beforeLoadData(type) {
        if (type === 'r') {
          this.page = 1;
        } else {
          this.page += 1;
        }
        this.loadData(this.page)
      },
      loadData(page) {
        this.loading = true;
        let p = {}
        for (let key in this.dataObj) {
          if (this.dataObj[key] !== null && this.dataObj[key] !== undefined && this.dataObj[key] !== '') {
            p[key] = this.dataObj[key]
          }
        }
        p.page = page;
        p.page_size = 16
        this.rq.get('/merapi/sorting/v2/client/client-detail', {params: p}).then(res => {
          this.loading = false;
          this.LOCK_STATUS = false;
          this.loadingType = res.data.data.length === 16 ? 'more' : 'nomore';

          if (page === 1) {
            this.datalist = res.data.data
          } else {
            this.datalist = [...this.datalist, ...res.data.data];
          }


          this.otherInfo = (res.data.other instanceof Array) ? null : res.data.other;
          let that = this;
          // this.$nextTick(() => {
          //   let newList = JSON.parse(JSON.stringify(that.datalist))
          //   newList.forEach((item, index) => {
          //     if (!item.client.nameScale) {
          //       let domContainerWidth = that.$refs['entity-name-container' + index][0].clientWidth;
          //       let domTextWidth = that.$refs['entity-name' + index][0].clientWidth;
          //
          //       if (domTextWidth <= domContainerWidth) {
          //         item.client.nameScale = 'scale(1)';
          //       } else {
          //         let r = domContainerWidth / domTextWidth;
          //         item.client.nameScale = 'scale(' + r + ')'
          //       }
          //     }
          //
          //   })
          //   that.datalist = newList
          //
          // })
          this.batchGetId();
        }).catch(err => {
          console.log(err)
          this.loading = false;
          this.LOCK_STATUS = false;
        })
      },
      batchGetId: function () { // 批量操作id生成
        var that = this;
        var data = this.datalist;
        this.batch.sort = [];
        this.batch.print = [];
        if (data.length) {
          data.forEach(function (value) {
            if (value.sorting_status == 0 && !(value.exception_status == 4)) {// 需要去掉订单被删除的
              that.batch.sort.push(value.id);
            }
            if (!(value.exception_status == 4)) {
              that.batch.print.push(value.id);
            }

          });
        }
      },

      handleSearch(e) {
        if (e === '') {
          this.productList = []
        } else {
          this.productLoading = true
          this.rq.get('/merapi/sorting/v2/product/all?name=' + e + '&page_size=100').then(res => {
            console.log('prod', res)
            this.productList = res.data.data;
            this.productLoading = false;
          }).catch(e => {
            this.productLoading = false;
          })
        }
      },
      handleUserSearch(e) {
        if (e === '') {
          this.userList = []
        } else {
          this.userLoading = true
          this.rq.get('/merapi/sorting/v2/client/all?name=' + e + '&page_size=100').then(res => {
            console.log('prod', res)
            this.userList = res.data.data;
            this.userLoading = false;
          }).catch(e => {
            this.userLoading = false;
          })
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .single-sorting-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #F1F2F2;
    overflow: hidden;
    position: relative;

    .header {
      display: flex;
    }
  }

  .sortingformbox {
    background: #fff;
    display: flex;

    align-items: center;
    flex: 1;
    margin-left: 0.7%;
    .box-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .bgBlue+.bgBlue {
        margin-top: 10px;
      }
      .bgBlue {
        background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        height: 50px;
        outline: none;
        margin: 0 25px;
      }
    }

  }

  .sortingformbox /deep/ .ant-input, /deep/ .ant-select-selection__rendered, /deep/ .ant-select-selection, /deep/ .ant-cascader-picker-label {
    height: 80px !important;
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    line-height: 50px;
  }

  .sortingformbox /deep/ .ant-cascader-picker-label {
    line-height: unset;
  }
  .sortingformbox /deep/ .ant-form-item {
    margin-bottom: unset !important;
  }
  .sortingformbox /deep/ .ant-form-item-control-wrapper {
    width: 100%;
  }
  .sortingformbox /deep/ .ant-cascader-picker-label {
    line-height: unset;
  }
  .itemFormItem + .itemFormItem {
    margin-left: 20px;
  }
  .itemFormItem{
    width: 100%
  }
  .btn-form-line {
    height: 100%;
    width: 1px;
    background-color: #F1F2F2;
  }

  .form-container {
    padding-left: 25px;
    padding-right: 20px;
    width: 100%;
  }

  .input-label {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    line-height: 22px;
    margin-right: 6px;
  }

  .form-btn {
    width: 120px;
    height: 50px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .reset-btn {
    border: 1px solid #C6CBD4;
    font-size: 16px;
    font-weight: 600;
    color: #23409F;
  }

  .search-btn {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    border: none;
    margin-left: 15px;
  }

  .sorting-detail-view {
    /*margin-top: 10px;*/
    height: calc(660px - 70px);
    padding-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &::-webkit-scrollbar { // 直接复制黏贴到样式页.css，完美解决
      display: none;
      /* background-color:transparent; */
    }

    overflow-y: scroll;
    overflow-x: hidden;

    .sorting-detail-item {
      margin-top: 10px;
      overflow: hidden;
      height: 140px;
      background-color: #fff;
      /*box-shadow: 0px 5px 15px 0px rgba(56, 62, 68, 0.13);*/
      border-radius: 5px;
      padding: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
&.item-border {
        border-width: 1px;
        border-style: solid;
      }
      &.border-0 {
        border-color: #BC1D00;
      }
      &.border-1 {
        border-color: #BC1D00;
      }

      &.border-2 {
        border-color: #34BC00;
      }

      &.border-3 {
        border-color: #A6A6A6;
      }

      &.border-4 {
        border-color: #BC7B00;
      }
      .detail-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .prod-name {
          font-size: 23px;
          font-weight: 600;
          color: #0F0F0F;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: 1;
          margin-right: 20px;
        }

        .header-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .item-status {
          display: flex;
          align-items: center;
          font-size: 16px;

          font-weight: 600;
          color: #000000;

          & > div {
            width: 15px;
            height: 15px;
            background: #BC1D00;
            border-radius: 20px;
            margin-right: 8px;
          }

          .status-1 {
            background: #BC1D00;
          }

          .status-2 {
            background: #34BC00;
          }

          .status-3 {
            background: #A6A6A6;
          }

          .status-4 {
            background: #BC7B00;
          }

        }

        .out-sign-container {
          width: 25px;
          height: 25px;
        }

        .out-sign {
          width: 25px;
          height: 25px;
          background: #BC7B00;
          border-radius: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;

          font-weight: 600;
          color: #FFFFFF;
        }
      }

      .entity-name {
        /*overflow: hidden;*/

        /*text-overflow: ellipsis;*/


        display: inline-block;
        line-height: 2em;
        transform-origin: left center;
        white-space: nowrap;
        font-size: 23px;

        font-weight: 600;
        color: #0F0F0F;
      }

      .num-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          font-size: 20px;
          overflow: hidden;

          text-overflow: ellipsis;

          white-space: nowrap;

          font-weight: 600;
          color: #989898;

          & > span:nth-child(1) {
            color: #989898;
          }

          & > span:nth-child(2) {
            color: #BC1D00;
          }
        }
      }

      .border-line {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        background: #BC1D00;
      }

      .statusBg-1 {
        background: #BC1D00;
      }

      .statusBg-2 {
        background: #34BC00;
      }

      .statusBg-3 {
        background: #A6A6A6;
      }

      .statusBg-4 {
        background: #BC7B00;
      }

      .mark-view {
        display: flex;
        align-items: center;

        .mark-text {
          overflow: hidden;

            text-overflow: ellipsis;

            white-space: nowrap;
            color: #BC1D00;
          flex: 1;
        }
      }
    }

  }
  .sorting-detail-view-height {
    height: calc(640px - 176px);
    top: 106px;
    left: 0;
    right: 0;
  }
  .footer-container {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 11px 0px #242424;


    .sortPrint {
      .radioStyle {
        border-radius: 100%;
        line-height: 28px;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        font-size: 14px;
      }

      .nochoseClick {
        background: #e9e9e9;
        color: #fff;
      }

      .choseClick {
        color: #fff;
        background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
      }

      padding: 0 29px;
      font-size: 23px;

      font-weight: 600;
      text-align: center;
      color: #000000;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .footer-btns {
      display: flex;
      align-items: center;
    }

    .bgBlue {
      background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
      border-radius: 5px;
      border: none;
      height: 50px;
      outline: none;
      margin-left: 20px;
    }

    .sorting-sub-btn, .sorting-btn {
      height: 100%;
      background-color: transparent;
      border: none;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;

    }

    .sorting-btn {
      width: 120px;
    }

    .sorting-sub-btn {
      width: 56px;
    }

    .sorting-sub-btn:active {
      box-shadow: none !important;
    }

    .sorting-btn:active {
      box-shadow: none !important;
    }

    .line {
      width: 2px;
      height: 20px;
      background: rgba(250, 250, 250, 0.5);

    }

    /deep/ .popover-btn {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1E3B98;
      width: 160px;
      height: 60px;
    }
  }

  .drawBoxcommon {
    .title {
      font-size: 23px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 32px;
      letter-spacing: 1px;
      position: relative;
      padding: 30px 0px;

      .close {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 36px;
      }
    }
  }
  .soring-info-view {
    height: 96px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #6495ED;

    .info-item {
      background-color: #6495ED;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 20px;

      font-weight: 600;
      color: #fff;

      .info-item-title {

      }

      .info-item-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 28px;
        margin-bottom: 10px;
      }

      .info-item-value-unit {
        margin-left: 5px;
      }
    }

    .info-item + .info-item {
      margin-left: 5px;
    }
  }
  .content-view {
    width: 100%;
    height: 660px;
    position: relative;
  }
</style>